import {
	ContentWrapper,
	Header,
	Layout,
	Push,
	SubHeader,
} from 'components/strombestilling/components/StromStyles';
import {
	StromContext,
	storageNames,
} from 'components/strombestilling/helpers/strombestillingProvider';
import { stromUrlParams } from 'components/strombestilling/helpers/usePageNavigation';
import { contactSpan } from 'components/strombestilling/helpers/useValidate';
import { useAuth } from 'context/AuthProvider';
import { format, isValid, parse } from 'date-fns';
import { nb } from 'date-fns/locale';
import Sponsing from 'images/strombestilling/sponsing.inline.svg';
import Spacing from 'layouts/Spacing';
import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';

const StyledHeader = styled(Header)`
	${p =>
		p.theme.media.medium(css`
			font-weight: 500;
			font-size: 2.5rem;
			line-height: 2.9375rem;
			text-align: center;
		`)};
`;

const SponsingIcon = styled(Sponsing)`
	align-self: center;
	max-height: 17rem;
	${p =>
		p.theme.media.smallOnly(css`
			max-height: 14rem;
		`)};
`;

export default function OrderSuccess({ location }) {
	const { isAuthenticated, isLoading, logout } = useAuth();
	const { storeBeforeLogout, storedElektroOrder, clearElektroOrder } =
		useContext(StromContext);

	const params = new URLSearchParams(location?.search);
	const isManual = params.has(stromUrlParams.manual);

	const orderInfo = JSON.parse(
		localStorage.getItem(storageNames.logoutOrderInfo)
	);
	const startDate = orderInfo?.startDate
		? new Date(orderInfo?.startDate)
		: null;

	useEffect(() => {
		if (isAuthenticated && !isLoading) {
			storeBeforeLogout();

			// If has stored elektro order, clear the stored order
			if (!!storedElektroOrder) clearElektroOrder();

			logout();
		}
		// eslint-disable-next-line
	}, [
		isAuthenticated,
		isLoading,
		logout,
		storeBeforeLogout,
		storedElektroOrder,
	]);

	return (
		<Layout>
			<Spacing spacing={{ top: 'medium', bottom: 'xlarge' }}>
				<StyledHeader>Takk for bestillingen!</StyledHeader>
				<ContentWrapper>
					{(isManual && (
						<>
							<Push />
							For å kunne levere strøm til deg, trenger vi
							målernummer eller målepunkt-ID. Vi sender deg en
							e-post med informasjon, slik at du kan registrere
							nummeret når du har det klart.
							<Push />
							Dersom du har bestilt en ny strømavtale til boligen
							du bor i nå, sier vi opp den gamle avtalen for deg.
						</>
					)) || <SuccessText startDate={startDate} />}
					{/*
				<Push />
				<Feedback />
				*/}
				</ContentWrapper>
			</Spacing>
		</Layout>
	);
}

/**
 * Text to display on success page (both Strømbestilling and product order for Eletkro (when downpayment is selected))
 * @param {Date} startDate - Date to start the contract
 */
export const SuccessText = ({ startDate }) => {
	const stringToDate =
		typeof startDate === 'string'
			? parse(startDate, 'yyyy-MM-dd', new Date())
			: startDate;

	return (
		<>
			<SponsingIcon
				role="img"
				aria-label="Illustrasjon av en person som holder ballonger, og en som hopper med hendene i været."
				className="illustration"
			/>
			Velkommen som kunde i NTE. Vi sender deg en bekreftelse på e-post og
			sier opp din gamle avtale for deg, så det trenger du ikke tenke på.
			<Push />
			<SubHeader className="sub-header">Hva skjer nå?</SubHeader>
			Vi tar oss av alt det praktiske med å endre abonnementet ditt slik
			at du får strøm fra NTE.{' '}
			{(startDate && isValid(stringToDate) && (
				<div>
					Du har valgt oppstartsdato{' '}
					{format(startDate, 'd. MMMM', {
						locale: nb,
					})}
					, og vi skal gjøre vårt beste for å imøtekomme dette. Hvis
					du har spørsmål, ta kontakt med {contactSpan}, så hjelper i
					deg gjerne.
				</div>
			)) || (
				<span>
					Ta kontakt med {contactSpan} om du lurer på noe, så hjelper
					i deg gjerne.
				</span>
			)}
		</>
	);
};
