import { StromFormError } from 'components/strombestilling/components/StromFormMsgs';
import { Label } from 'components/strombestilling/components/StromStyles';
import React, { useId } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
	position: relative;
	z-index: 2;
	display: flex;
	flex-direction: column;
	flex: 1;
	color: ${p => p.theme.colors.grey900};
`;

export const InputFieldWrapper = styled.div`
	border: 1px solid;
	background: ${props => props.theme.colors.white};
	line-height: 1.5;
	${props =>
		props.isDisabled &&
		css`
			border-color: transparent;
			background: ${props => props.theme.colors.grey300};
			svg:first-child {
				color: ${p => p.theme.colors.grey500};
			}
		`}
	border-color: ${props =>
		props.isError
			? props.theme.colors.coral800
			: props.theme.colors.grey500};

	border-radius: 0.5rem;
	overflow: hidden;
	outline: ${p => (p.isError ? `2px solid ${p.theme.colors.coral800}` : '')};
	outline-offset: -2px;
	&:hover {
		${props =>
			!props.isDisabled &&
			!props.isError &&
			css`
				outline: 2px solid ${p => p.theme.colors.blue600};
				outline-offset: -2px;
			`}
	}
	&:focus-within {
		${props =>
			!props.isDisabled &&
			!props.isError &&
			css`
				outline: 2px solid ${p => p.theme.colors.blue600};
				outline-offset: -2px;
			`}
	}
	/* Remove wheel on number input */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		appearance: textfield;
		-moz-appearance: textfield;
	}
`;

export const Input = styled.input`
	border: none;
	padding: 0.2rem 1rem;
	font-family: 'Roboto';
	font-size: 1rem;
	color: ${p => p.theme.colors.grey900};
	&:focus {
		outline: none;
	}
`;

export function preventScrollNumberChange(e) {
	// Neccessary for all input of type="number"
	// Based on https://medium.com/modernnerd-code/how-to-disabled-scrolling-on-html-number-input-in-react-6548841166fb
	e.target.blur();
	e.stopPropagation();
	setTimeout(() => {
		e.target.focus();
	}, 0);
}

export function preventButtonNumberChange(e) {
	// Neccessary for all input of type="number"
	if (e.keyCode === 38 || e.keyCode === 40) {
		e.preventDefault();
	}
}

export default function InputField({
	error,
	title,
	placeholder,
	type = 'text',
	disabled,
	inputRef,
	...rest
}) {
	const id = useId();

	return (
		<Wrapper>
			{title && <Label htmlFor={id}>{title}</Label>}
			<InputFieldWrapper isError={error} isDisabled={disabled}>
				<Input
					type={type}
					id={id}
					placeholder={placeholder || null}
					disabled={disabled}
					ref={inputRef}
					aria-invalid={error}
					onWheel={e => {
						if (type === 'number') {
							preventScrollNumberChange(e);
						}
					}}
					onKeyDown={e => {
						if (type === 'number') {
							preventButtonNumberChange(e);
						}
					}}
					{...rest}
				/>
			</InputFieldWrapper>
			{error && <StromFormError error={error} />}
		</Wrapper>
	);
}
