import {
	Button,
	ContentWrapper,
	Header,
	Layout,
} from 'components/strombestilling/components/StromStyles';
import usePageNavigation, {
	stromPages,
} from 'components/strombestilling/helpers/usePageNavigation';
import React from 'react';

export default function NotFoundPage() {
	const { navigatePage } = usePageNavigation();

	return (
		<Layout>
			<Header>Beklager, denne siden finnes ikke</Header>
			<ContentWrapper>
				Klikk på knappen under, så tar vi deg til den første siden i
				bestillingen.
				<Button
					onClick={() => {
						navigatePage(stromPages.login.url);
					}}>
					Tilbake til forsiden
				</Button>
			</ContentWrapper>
		</Layout>
	);
}
