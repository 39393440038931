import MeterInfoPopup from 'components/strombestilling/components/MeterInfoPopup';
import { ProductBoxMobileOnly } from 'components/strombestilling/components/ProductBox';
import {
	StromFormError,
	StromFormInfo,
} from 'components/strombestilling/components/StromFormMsgs';
import {
	Button,
	Form,
	Header,
	Layout,
	Paragraph,
	Push,
	StyledListOfRuleErrors,
} from 'components/strombestilling/components/StromStyles';
import StyledCheckbox from 'components/strombestilling/components/StyledCheckbox';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import usePageNavigation, {
	stromPages,
} from 'components/strombestilling/helpers/usePageNavigation';
import useSale, {
	salgsStatuser,
} from 'components/strombestilling/helpers/useSale';
import useTracking, {
	steps,
} from 'components/strombestilling/helpers/useTracking';
import useValidate from 'components/strombestilling/helpers/useValidate';
import LoadingPage from 'components/strombestilling/pages/helperPages/loadingPage';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export default function PickMeter() {
	const { navigatePage } = usePageNavigation();
	const { order, updateOrder, searchResult } = useContext(StromContext);
	const { getValidatedMeters, isValidating } = useValidate();
	const { trackStepCompleted } = useTracking();
	const { updateSaleStatus } = useSale();

	const [formError, setFormError] = useState('');
	const [meters, setMeters] = useState([]);
	const [infoArray, setInfoArray] = useState([]);

	useEffect(() => {
		async function runValidate() {
			const validatedMeters = await getValidatedMeters([
				...searchResult.maalere,
			]);
			setMeters(validatedMeters.meters);
			setInfoArray(validatedMeters.infoArray);
		}
		runValidate();
		// eslint-disable-next-line
	}, [searchResult?.maalere]);

	const tallTilStringEnum = {
		1: 'én',
		2: 'to',
		3: 'tre',
		4: 'fire',
		5: 'fem',
		6: 'seks',
		7: 'sju',
		8: 'åtte',
		9: 'ni',
		10: 'ti',
	};

	const tallTilString = tall => {
		return tallTilStringEnum[tall] || tall;
	};

	if (!meters?.length > 0 || isValidating) {
		return <LoadingPage />;
	}
	return (
		<Layout>
			<Header>Velg strømmålere</Header>
			<Paragraph>
				{meters.filter(meter => meter.isInvalid)?.length ===
				meters?.length
					? 'Du har ingen målere på '
					: `Du har ${tallTilString(meters?.length)} målere på `}
				<b>
					{searchResult.maalerAdresse?.gate}{' '}
					{searchResult.maalerAdresse?.husnummer}
				</b>
				{meters.filter(meter => meter.isInvalid)?.length ===
				meters?.length
					? ' som du kan bestille til, se mer info nederst på denne siden:'
					: meters.filter(meter => meter.isInvalid)?.length >= 1
					? `, det er kun ${tallTilString(
							meters.filter(meter => !meter.isInvalid)?.length
					  )} du kan bestille til, se mer info nederst på denne siden:`
					: ', velg de du vil bestille til:'}
			</Paragraph>
			<Form
				onSubmit={async e => {
					e.preventDefault();
					setFormError('');

					let pickedMeters = [];

					for (let i = 0; i < searchResult.maalere?.length; i++) {
						const meter = e.target.meter[i];

						if (meter?.checked) {
							pickedMeters.push(JSON.parse(meter.value));
						}
					}

					if (!pickedMeters?.length) {
						setFormError(
							'Vi trenger minst én måler for å fullføre bestillingen'
						);
						return;
					}

					updateOrder({
						maalere: pickedMeters,
						maalerAdresse: searchResult.maalerAdresse,
						isManual: false,
					});

					trackStepCompleted(steps[3]);
					updateSaleStatus(salgsStatuser.meterFound);
					navigatePage(stromPages.addStartdate.url);
				}}>
				<CheckboxWrapper>
					{meters.map((meter, i) => {
						return (
							<StyledCheckbox
								disabled={meter.isInvalid}
								defaultValue={
									!meter.isInvalid &&
									(!order?.maalere?.length ||
										order?.maalere
											?.map(m => m.maalepunktId)
											?.includes(meter.maalepunktId))
								}
								label={
									'Målernummer: ' + meter.maalernummer || i
								}
								value={JSON.stringify(meter)}
								onChange={() => {
									setFormError('');
								}}
								name="meter"
								key={i}
							/>
						);
					})}
				</CheckboxWrapper>
				{formError && <StromFormError error={formError} />}
				<Button type="submit">Bestill til disse målepunktene</Button>
			</Form>
			<MeterInfoPopup />
			{infoArray?.length > 0 && (
				<>
					<StromFormInfo
						info={
							infoArray?.length === 1 ? (
								infoArray[0]
							) : (
								<StyledListOfRuleErrors
									single={infoArray?.length < 2}>
									{infoArray.map((info, i) => (
										<li key={i}>{info}</li>
									))}
								</StyledListOfRuleErrors>
							)
						}
					/>
					<Push />
					<ProductBoxMobileOnly />
				</>
			)}
		</Layout>
	);
}
