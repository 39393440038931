import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from 'components/forms/Box';
import { ClearButton } from 'components/strombestilling/components/StromStyles';

import React from 'react';
import styled from 'styled-components';

const BoxWrap = styled(Box)`
	padding: 20px;
`;

const Wrapper = styled.dialog`
	border: 0;
	padding: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	position: relative;
`;

const CloseButton = styled(ClearButton)`
	position: absolute;
	color: ${p => p.theme.colors.blue800};
	font-size: 1.125rem;
	line-height: 1;
	right: 0;
	top: 0;
	padding: 24px;
	z-index: 1;
`;

const Title = styled.h3`
	font-size: 1.125rem;
	line-height: 1.3125rem;
	font-weight: 400;
	color: ${p => p.theme.colors.grey900};
	margin: 0;
`;

export const InfoBoxVariants = {
	visible: {
		opacity: 1,
		y: '0',
		transition: {
			duration: 0.3,
			y: {
				type: 'spring',
			},
			opacity: {
				duration: 0.1,
				delay: 0.2,
			},
		},
	},
	hidden: {
		opacity: 0,
		y: '10px',
		transition: {
			duration: 0.2,
			opacity: {
				duration: 0.1,
			},
		},
	},
};

export const EditFormVariants = {
	visible: {
		scale: 1,
		opacity: 1,
		transition: {
			duration: 0.5,
			opacity: {
				duration: 0.3,
				delay: 0.2,
			},
			scale: {
				type: 'spring',
				bounce: 0.2,
			},
		},
	},
	hidden: {
		scale: 0.5,
		opacity: 0,
		transition: {
			duration: 0.3,
			type: 'spring',
			opacity: {
				duration: 0.1,
			},
		},
	},
};

function InlineModal({ title, onClose, hideClose, children, id, ...rest }) {
	return (
		<BoxWrap>
			{!hideClose && (
				<CloseButton
					type="button"
					aria-label="Lukk dialogboks"
					onClick={onClose}>
					<FontAwesomeIcon icon={faTimes} />
				</CloseButton>
			)}
			<Wrapper
				aria-label={`${title}`}
				role="dialog"
				aria-modal="true"
				aria-live="polite"
				{...rest}>
				<Title>{title}</Title>
				{children}
			</Wrapper>
		</BoxWrap>
	);
}

export default InlineModal;
