import Link, { AnchorLink } from 'components/Link';
import InputField from 'components/strombestilling/components/InputField';
import MeterInfoPopup from 'components/strombestilling/components/MeterInfoPopup';
import { addressObjectToString } from 'components/strombestilling/components/SearchAddressField';
import {
	StromFormCelebration,
	StromFormInfo,
} from 'components/strombestilling/components/StromFormMsgs';
import {
	Button,
	ButtonSecondary,
	ContentWrapper,
	Form,
	Header,
	Layout,
	Paragraph,
	Push,
	RuleErrorWrapper,
	StyledListOfRuleErrors,
} from 'components/strombestilling/components/StromStyles';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import useBackend from 'components/strombestilling/helpers/useBackend';
import usePageNavigation, {
	stromPages,
} from 'components/strombestilling/helpers/usePageNavigation';
import useSale, {
	salgsStatuser,
} from 'components/strombestilling/helpers/useSale';
import useTracking, {
	steps,
} from 'components/strombestilling/helpers/useTracking';
import useValidate from 'components/strombestilling/helpers/useValidate';
import { track } from 'context/AnalyticsProvider';
import LoadingDots from 'images/icons/loading-dots-animation.inline.svg';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export default function SearchMeternumber() {
	const { navigatePage } = usePageNavigation();
	const { trackStepCompleted, trackButtonClicked } = useTracking();
	const { isLoading, getMeterInfoFromMeternumber } = useBackend();
	const { updateSaleStatus } = useSale();
	const { order, updateOrder, searchResult, storedElektroOrder } =
		useContext(StromContext);

	const inputRef = useRef(null);
	const [hasMeter, setHasMeter] = useState(false);

	useEffect(() => {
		updateSaleStatus(salgsStatuser.meterNotFound);
		// eslint-disable-next-line
	}, []);

	return (
		<Layout>
			<Header>Søk etter målernummer eller målepunkt-ID</Header>
			<ContentWrapper>
				<Paragraph>
					Om du ikke har tilgang på det akkurat nå, kan du fortsette
					bestillingen uten. Da sender vi deg en e-post i etterkant
					slik at du kan registrere nummeret når du har det klart.
				</Paragraph>
				<MeterInfoPopup />
				<Push />
				<MeterSearchForm
					inputRef={inputRef}
					isLoading={isLoading}
					search={maalernummer =>
						getMeterInfoFromMeternumber(
							maalernummer,
							searchResult.maalerAdresse.postnummer
						)
					}
					setHasMeter={setHasMeter}
					onDone={meter => {
						updateOrder({
							maalere: [
								{
									maalernummer: meter.maalernummer,
									maalepunktId: meter.maalepunktId,
								},
							],
							maalerAdresse:
								meter.adresse || searchResult.maalerAdresse,
							isManual: false,
						});
						trackStepCompleted(steps[3]);
						updateSaleStatus(salgsStatuser.meterFoundAfterSearch);
						navigatePage(stromPages.addStartdate.url);
					}}
				/>

				{!hasMeter && (
					<>
						{order?.isElektroOrder ? (
							<>
								{storedElektroOrder?.product?.internalTitle && (
									<StromFormInfo
										info={
											<span>
												Bestilling av{' '}
												<b>
													{
														storedElektroOrder
															?.product
															?.internalTitle
													}
												</b>{' '}
												på avbetaling med{' '}
												{
													storedElektroOrder?.power
														?.product
												}{' '}
												kan ikke gjennomføres uten
												målernummer eller målepunkt-ID.
												Du kan{' '}
												<Link
													to={`/produkter/bestill?produkt=${storedElektroOrder?.product?.slug}`}>
													gå tilbake og bestille
													produktet med faktura som
													betalingsmetode
												</Link>
												.
											</span>
										}
									/>
								)}
								<ButtonSecondary
									type="button"
									onClick={() => {
										trackButtonClicked(
											'Tilbake til adressevelgeren'
										);
										navigatePage(stromPages.pickPath.url);
									}}>
									Tilbake til adressevelgeren
								</ButtonSecondary>
							</>
						) : (
							<ButtonSecondary
								type="button"
								title="Fortsett uten målernummer/målepunkt-ID"
								onClick={() => {
									// This is now a manual order
									updateOrder({
										maalere: [],
										maalerAdresse:
											searchResult.maalerAdresse,
										isManual: true,
										manualMsg:
											'Bruker hadde ikke målernummer' +
											(inputRef.current?.value
												? `, og søkte nyligst på målernummer/målepunktId ${inputRef.current?.value}`
												: ''),
									});
									trackButtonClicked(
										'Fortsett uten målernummer/målepunkt-ID'
									);
									trackStepCompleted(
										steps[3],
										'Ingen måler, manuell rute'
									);
									navigatePage(stromPages.addStartdate.url);
								}}>
								Fortsett uten målernummer/målepunkt-ID
							</ButtonSecondary>
						)}
					</>
				)}
			</ContentWrapper>
		</Layout>
	);
}

/**
 * Basic search for meter number towards strombestilling-api, possible to reuse
 * @param {Object} props - Props.
 * @param {RefObject} props.inputRef - The reference to the meter number search input field.
 * @param {String} props.buttonText - Text on search button.
 * @param {Boolean} props.isLoading - Are any calls loading
 * @param {Function} props.search - Function to be used for meter search.
 * @param {Function} props.setHasMeter - Function to be called if form has found a meter.
 * @param {React.ReactNode} props.searchError - What message to display to the user when the search fails.
 * @param {String} props.tariffId - Tariff id used for validation.
 * @param {Function} props.onDone - Function to be called when meter is successfully found.
 * @returns {JSX.Element}
 */
export function MeterSearchForm({
	inputRef,
	buttonText,
	isLoading,
	search,
	setHasMeter,
	searchError,
	tariffId,
	onDone,
}) {
	const { getValidatedMeters, orderErrors, isValidating } = useValidate();

	const [formError, setFormError] = useState('');
	const [foundMeter, _setFoundMeter] = useState(null);

	function setFoundMeter(meter) {
		if (meter) {
			setHasMeter?.(true);
		} else {
			setHasMeter?.(false);
		}
		_setFoundMeter(meter);
	}

	async function handleSearch() {
		setFormError('');
		setFoundMeter(null);

		track('Button Clicked', {
			label: 'Søk etter måler',
			category: window?.location?.pathname,
			href: window?.location?.href,
		});

		const maalernummerInput = inputRef.current?.value
			?.replace(' ', '')
			?.trim();

		if (!maalernummerInput) {
			setFormError(
				'Vi trenger målernummer eller målepunkt-ID for å søke'
			);
			return;
		}

		// The search can handle both meter number and meter point id
		const meters = await search(maalernummerInput);

		if (!meters || meters?.error || meters?.length === 0) {
			setFormError(
				searchError || (
					<span>
						Vi fant ingen treff på måleren. Prøv på nytt eller finn
						både målernummer og målepunkt-ID ved å logge deg inn på{' '}
						<AnchorLink
							href="https://minside.elhub.no/"
							target="_blank"
							rel="noreferrer"
							title="Finn målernummer og målepunkt-ID ved å
				logge deg inn på Min Side hos Elhub">
							"Min side" hos Elhub
						</AnchorLink>
						.
					</span>
				)
			);
			return;
		} else {
			// Will always be just one in the list, since we searched by an id
			const meter = meters[0];

			const validationResult = await getValidatedMeters(meters, tariffId);
			if (validationResult.showError) {
				setFormError(
					<RuleErrorWrapper>
						{orderErrors.ruleBroken.single}
						<StyledListOfRuleErrors
							single={validationResult?.infoArray?.length < 2}>
							{validationResult?.infoArray.map((info, i) => (
								<li key={i}>{info}</li>
							))}
						</StyledListOfRuleErrors>
					</RuleErrorWrapper>
				);
				return;
			}
			setFoundMeter(meter);
		}
	}

	return (
		<Form
			onSubmit={e => {
				e.preventDefault();
				setFormError('');

				if (isLoading) {
					return;
				}

				if (foundMeter) {
					track('Button Clicked', {
						label: 'Velg måler',
						category: window?.location?.pathname,
						href: window?.location?.href,
					});
					onDone?.(foundMeter);
				} else {
					handleSearch();
				}
			}}>
			<InputAndButtonWrapper>
				<InputField
					name="maalernummer"
					title="Målernummer eller målepunkt-ID"
					aria-description="Søk etter og velg målernummer eller målepunkt-ID"
					type="number"
					inputRef={inputRef}
					error={formError}
					onChange={() => {
						setFormError('');
					}}
				/>
				<Button
					type="button"
					style={{
						padding: '29px 16px',
						minWidth: '4rem',
						marginTop: '29px',
						marginLeft: '4px',
					}}
					disabled={isLoading || isValidating}
					onClick={handleSearch}>
					{isLoading || isValidating ? (
						<LoadingDots style={{ fill: 'white' }} />
					) : (
						'Søk'
					)}
				</Button>
			</InputAndButtonWrapper>
			{foundMeter && (
				<>
					<StromFormCelebration
						info={
							<div>
								Vi fant måler med målernummer{' '}
								<b>{foundMeter.maalernummer}</b> og målepunkt-ID{' '}
								<b>{foundMeter.maalepunktId}</b> på adressen{' '}
								<b>
									{addressObjectToString(foundMeter.adresse)}
								</b>
								.
							</div>
						}
					/>
					<Button
						type="submit"
						disabled={isLoading || isValidating || formError}>
						{isLoading || isValidating
							? 'Laster...'
							: buttonText || 'Velg måler'}
					</Button>
				</>
			)}
		</Form>
	);
}

const InputAndButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;
