import { useState, useEffect } from 'react';
import { marked } from 'marked';

export default function useProductInfo(tariff) {
	const [loading, setLoading] = useState(true);
	const [productInfo, setProductInfo] = useState(tariff);

	useEffect(() => {
		if (!tariff?.tariff && !tariff?.tariffId) return;

		fetch(
			`${process.env.GATSBY_STROM_SERVER_URL}/tariffer/${
				tariff?.tariff || tariff?.tariffId
			}`
		)
			.then(async res => {
				if (res.status !== 200) {
					setProductInfo(tariff);
					setLoading(false);
					return;
				}

				const json = await res.json();

				const vilkaar = json?.vilkaar.map(item => ({
					tekst:
						item.tekst.charAt(0).toUpperCase() +
						item.tekst.slice(1),
				}));

				const html = !!vilkaar.length
					? vilkaar
							?.map(item =>
								item.tekst ? `<li>${item.tekst}</li>` : ''
							)
							.join('')
					: '';

				const termsHtml = `<ul>${html}</ul>`;

				setProductInfo({
					...tariff,
					navn: json?.navn || tariff?.name,
					prisbeskrivelse: json?.prisbeskrivelse || tariff?.priceDesc,
					kundetype: json?.kundetype || tariff?.kundetype,
					intro: json?.beskrivelse || tariff?.intro,
					termsHtml: html
						? termsHtml
						: marked.parse(tariff?.terms || ''),
					footnote: json?.fotnote || '',
					vilkaar,
				});
				setLoading(false);
			})
			.catch(e => {
				console.error('Could not fetch tariff data', e);
				setProductInfo(tariff);
				setLoading(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tariff?.tariff, tariff?.tariffId]);

	return {
		loading,
		productInfo: !loading && productInfo,
	};
}
