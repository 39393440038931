import Layout from 'components/layout';
import {
	Header,
	Layout as StromLayout,
} from 'components/strombestilling/components/StromStyles';
import SmallWidth from 'layouts/small-width';
import Spacing from 'layouts/Spacing';
import React from 'react';

/**
 * Simple wrapper to ensure the pages in the maalernummer subdomain have the same page styling
 * @param {Object} props - Props.
 * @param {Object} props.location - The current location object from the router.
 * @param {String} props.header - The header of the page.
 * @param {ReactNode} props.children - The children of the wrapper. Basically, the page content.
 * @returns {JSX.Element}
 */
export function StromSubpagesWrapper({ location, header, children }) {
	return (
		<Layout
			location={location}
			pagesettings={{
				segment: 'Privat',
			}}>
			<Spacing
				spacing={{
					top: 'xlarge',
					bottom: 'xlarge',
					tabletTop: 'medium',
				}}>
				<SmallWidth>
					<StromLayout>
						<Header>{header}</Header>
						{children}
					</StromLayout>
				</SmallWidth>
			</Spacing>
		</Layout>
	);
}
