import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Button from 'components/forms/Button';
import { stromPagesBaseUrl } from 'components/strombestilling/helpers/usePageNavigation';
import { getUrl } from 'libs/content';

const NotAvailable = styled.p`
	font-style: italic;
	margin: 20px 0 0 0;
`;

export default function OrderButton({
	product,
	productPrimarySegment = 'Privat',
	location,
	...props
}) {
	const { vervekode } = Object.fromEntries(
		new URLSearchParams(location?.search?.split('?')?.pop())
	);

	if (!product) return null;

	if (
		product?.orderButton === 'En annen side' &&
		product?.orderFormPage?.slug
	) {
		return (
			<Button
				onClick={() => navigate(getUrl(product?.orderFormPage?.slug))}
				{...props}>
				Bestill {product?.name}
			</Button>
		);
	}

	if (product.orderButton === 'Ikke vis bestill-knapp') {
		return (
			<NotAvailable>
				Denne avtalen er ikke lengre tilgjengelig for salg.
			</NotAvailable>
		);
	}

	if (productPrimarySegment === 'Bedrift') {
		return (
			<Button
				onClick={() =>
					navigate(
						'/bedrift/strom-og-energitjenester/stromavtaler/bestill'
					)
				}
				{...props}>
				Bestill {product?.name}
			</Button>
		);
	}

	return (
		<Button
			onClick={() => {
				const orderUrl =
					`${stromPagesBaseUrl}?produkt=${product?.tariff}` +
					(vervekode ? `&vervekode=${vervekode}` : '');
				return navigate(orderUrl);
			}}
			{...props}>
			Bestill {product?.name}
		</Button>
	);
}
