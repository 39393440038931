import React, { useId, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';

import {
	RequiredAsterisk,
	StromFormError,
} from 'components/strombestilling/components/StromFormMsgs';
import { Label } from 'components/strombestilling/components/StromStyles';

const CheckboxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 100%;
	width: max-content;
	gap: 8px;
`;

const CheckboxContentLabel = styled.label`
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
	gap: 1rem;
	color: ${p =>
		p.disabled ? p.theme.colors.grey700 : p.theme.colors.grey900};

	&:hover {
		.checkbox {
			outline: 2px solid ${p => p.theme.colors.blue600};
			outline-offset: -2px;
			outline: ${p => p.disabled && 'none'};
		}
	}
	&:focus-within {
		.checkbox {
			outline: 2px solid ${p => p.theme.colors.blue600};
			outline-offset: -2px;
			outline: ${p => p.disabled && 'none'};
		}
	}
`;

const HiddenCheckboxInput = styled.input`
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const Checkbox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${p =>
		p.isChecked ? p.theme.colors.blue600 : p.theme.colors.grey500};
	background-color: ${p => (p.disabled ? p.theme.colors.grey400 : 'white')};
	${props =>
		!props.isChecked &&
		css`
			box-shadow: ${p => p.theme.utils.boxShadowSoft};
		`}
	border: 1px solid;
	border-radius: ${p => p.theme.utils.borderRadius};
	height: 1.5rem;
	width: 1.5rem;
	align-self: flex-start;
`;

const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
`;

const LabelWrapper = styled.div`
	font-size: 0.875rem;
	text-align: initial;
`;

export default function StyledCheckbox({
	title,
	label,
	defaultValue,
	onChange,
	disabled,
	required,
	error,
	...rest
}) {
	const id = useId();
	const [isChecked, _setIsChecked] = useState(defaultValue || false);
	const setIsChecked = newValue => {
		_setIsChecked(newValue);
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<CheckboxWrapper>
			{title && <Label>{title}</Label>}
			<CheckboxContentLabel htmlFor={id} disabled={disabled}>
				<HiddenCheckboxInput
					type="checkbox"
					checked={isChecked}
					aria-checked={isChecked}
					required={required}
					onChange={() => {
						setIsChecked(!isChecked);
					}}
					id={id}
					disabled={disabled}
					aria-invalid={error}
					{...rest}
				/>
				<Checkbox
					className="checkbox"
					isChecked={isChecked}
					disabled={disabled}
					aria-hidden="true">
					<IconWrapper>
						{isChecked && (
							<FontAwesomeIcon
								icon={faCheck}
								aria-hidden="true"
							/>
						)}
					</IconWrapper>
				</Checkbox>
				<LabelWrapper>
					{label}
					{required && (
						<>
							<RequiredAsterisk />
						</>
					)}
				</LabelWrapper>
			</CheckboxContentLabel>
			{error && <StromFormError error={error} />}
		</CheckboxWrapper>
	);
}
