import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshakeSimple } from '@fortawesome/pro-regular-svg-icons/faHandshakeSimple';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';

import { AnchorLink } from 'components/Link';
import Box from 'components/forms/Box';
import { SubHeader } from 'components/strombestilling/components/StromStyles';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import spotpris from 'images/strombestilling/spotpris.svg';
import SanitizeHtml from 'libs/SanitizeHtml';

const TopWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 30px;
	margin-bottom: 20px;
	${p =>
		p.theme.media.large(css`
			margin-bottom: 30px;
		`)};
`;

const Image = styled.img`
	height: 7.5rem;
	${p =>
		p.theme.media.mediumDown(css`
			display: none;
		`)};
`;

const PriceAndNameWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	${p =>
		p.theme.media.smallOnly(css`
			gap: 0.5rem;
		`)};
`;

const Price = styled.div`
	width: 100%;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.5rem;
	display: flex;
	align-items: center;
	${p =>
		p.theme.media.smallOnly(css`
			justify-content: flex-start;
		`)};
`;

const BlueSpan = styled.span`
	color: ${p => p.theme.colors.blue800};
	font-size: 1.25rem;
	line-height: 1.8125rem;
	font-weight: 700;
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 1rem;
		`)};
`;

const NameWrapper = styled.div`
	display: flex;
	align-items: center;
	svg {
		font-size: 1.5rem;
		line-height: 1.8rem;
		margin-left: 1rem;
		color: ${p => p.theme.colors.blue600};
	}
`;

const Name = styled.h2`
	margin: 0;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 1.8rem;
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 1.25rem;
			line-height: 1.1875rem;
		`)};
`;

const BottomWrapper = styled.div`
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.5rem;
	flex: none;
	order: 2;
	align-self: stretch;
	flex-grow: 0;
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 0.875rem;
		`)};
`;

const VilkaarList = styled.ul`
	margin-left: 20px;
	li {
		margin-bottom: 10px;
	}
	svg {
		color: ${p => p.theme.colors.blue600};
	}
`;

const BoxWrapperMobileOnly = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	${p =>
		p.theme.media.medium(css`
			display: none;
		`)}
`;

const Disclaimer = styled.p`
	font-size: 14px;
	line-height: 22px;
	color: ${p => p.theme.colors.grey700};
	margin: 20px 0 0;
`;

export default function ProductBox() {
	const { order } = useContext(StromContext);
	const { tariffData } = order;

	function splitBeskrivelseOnPris(beskrivelse) {
		return beskrivelse.split(/(\d+,?\d*)/);
	}

	return (
		<Box badge="DU HAR VALGT">
			<TopWrapper>
				<Image src={spotpris} alt="" />
				<PriceAndNameWrapper>
					<NameWrapper>
						<div>
							<Name>{tariffData?.navn || 'Laster...'}</Name>
							{order?.vervekode && (
								<FontAwesomeIcon icon={faHandshakeSimple} />
							)}
						</div>
					</NameWrapper>
					<Price>
						{(tariffData?.prisbeskrivelse && (
							<span>
								{splitBeskrivelseOnPris(
									tariffData.prisbeskrivelse
								).map((beskrivelse, i) => {
									if (
										beskrivelse.indexOf(
											tariffData?.navn
										) !== -1 ||
										beskrivelse.indexOf('Spotpris') !==
											-1 ||
										beskrivelse.match(/\d/)
									) {
										return (
											<BlueSpan key={i}>
												{beskrivelse}
											</BlueSpan>
										);
									} else {
										return (
											<span key={i}>{beskrivelse}</span>
										);
									}
								})}
							</span>
						)) || <>Laster...</>}
					</Price>
				</PriceAndNameWrapper>
			</TopWrapper>
			<BottomWrapper>
				<SanitizeHtml html={tariffData?.beskrivelse} as="span" />

				{tariffData?.kundetype === 'FORBRUKER' && (
					<div>
						Du finner fullstendig{' '}
						<AnchorLink
							target="_blank"
							href="/strom/prisliste"
							title="Se prisliste over alle våre strømavtaler">
							prisliste over alle våre avtaler her
						</AnchorLink>
						.
					</div>
				)}
				{(tariffData?.vilkaar?.length && (
					<>
						<br />
						<VilkaarList className="fa-ul">
							{tariffData?.vilkaar.map((vilkaar, i) => {
								return (
									<li key={i}>
										<span className="fa-li">
											<FontAwesomeIcon
												icon={faCaretRight}
											/>
										</span>
										{vilkaar.tekst}
									</li>
								);
							})}
						</VilkaarList>
					</>
				)) || <></>}

				{tariffData?.fotnote && (
					<Disclaimer>
						<SanitizeHtml html={tariffData?.fotnote} as="span" />
					</Disclaimer>
				)}
			</BottomWrapper>
		</Box>
	);
}

export function ProductBoxMobileOnly({ header }) {
	return (
		<BoxWrapperMobileOnly>
			<SubHeader>{header || 'Du bestiller'}</SubHeader>
			<ProductBox />
		</BoxWrapperMobileOnly>
	);
}
