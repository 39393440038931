import { ProductBoxMobileOnly } from 'components/strombestilling/components/ProductBox';
import {
	ContentWrapper,
	Header,
	Layout,
	Push,
	SubHeader,
} from 'components/strombestilling/components/StromStyles';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import { SpotprisInfo } from 'components/strombestilling/pages/loginStrombestilling';
import { useAuth } from 'context/AuthProvider';
import Avtale from 'images/strombestilling/avtale.inline.svg';
import React, { useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';

const AvtaleIcon = styled(Avtale)`
	align-self: center;
	max-height: 17rem;
	${p =>
		p.theme.media.smallOnly(css`
			max-height: 14rem;
		`)};
`;

export default function SalesCodeUsed() {
	const { isAuthenticated, isLoading, logout } = useAuth();
	const { storeBeforeLogout } = useContext(StromContext);

	useEffect(() => {
		if (isAuthenticated && !isLoading) {
			storeBeforeLogout();
			logout();
		}
	}, [isAuthenticated, isLoading, logout, storeBeforeLogout]);

	return (
		<Layout>
			<ContentWrapper>
				<AvtaleIcon
					role="img"
					aria-label="Illustrasjon av to hender i et håndtrykk"
				/>
				<Push />
				<Header>Koden er allerede brukt</Header>
				Koden som tilhører lenken du klikket på i SMS-en er allerede
				brukt.
				<Push />
				<SubHeader>Ønsker du å bestille til en annen måler?</SubHeader>
				Snakk med den som gav deg koden, så kan de sende deg en ny en.
				<Push />
				<ProductBoxMobileOnly header="Du bestilte" />
				<Push />
				<SpotprisInfo />
			</ContentWrapper>
		</Layout>
	);
}
