import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import usePageNavigation, {
	stromPages,
	stromUrlParams,
} from 'components/strombestilling/helpers/usePageNavigation';
import LoadingPage from 'components/strombestilling/pages/helperPages/loadingPage';
import React, { useContext, useEffect } from 'react';

export default function LoginSuccess() {
	const { navigatePage } = usePageNavigation();
	const { order } = useContext(StromContext);

	useEffect(() => {
		if (order.isBedrift) {
			navigatePage(stromPages.addOrgNumber.url);
		} else {
			navigatePage(stromPages.pickPath.url, stromUrlParams.login);
		}
		// eslint-disable-next-line
	}, [order]);

	return <LoadingPage />;
}
