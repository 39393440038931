import BlankButton from 'components/forms/BlankButton';
import Popup from 'components/popup/Popup';
import { SubHeader } from 'components/strombestilling/components/StromStyles';
import { usePopup } from 'context/PopupProvider';
import React from 'react';
import styled from 'styled-components';

const ModalHeader = styled(SubHeader)`
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.75rem;
	margin-bottom: 0.5rem;
`;

export default function MeterInfoPopup({
	customTitle,
	buttonStyle,
	meterPointIdOnly,
}) {
	const { activePopup, setActivePopup } = usePopup();

	const title =
		customTitle || 'Hvordan finner jeg målernummer eller målepunkt-ID?';

	return (
		<>
			<BlankButton
				type="button"
				aria-haspopup={true}
				aria-controls="power-meter-information"
				aria-expanded={activePopup === 'power-meter-information'}
				style={buttonStyle}
				onClick={e => {
					e?.preventDefault();
					setActivePopup('power-meter-information');
				}}>
				{title}
			</BlankButton>
			<Popup id="power-meter-information" title={title} size="small">
				{meterPointIdOnly ? (
					<>
						<ModalHeader>
							Hvordan finner jeg målepunkt-ID?
						</ModalHeader>
						<ul>
							<li>
								Du kan finne målepunkt-ID{' '}
								<b>på en gammel faktura</b>.
							</li>
							<li>
								<b>Har du en app eller “Min side”</b> knyttet
								til den gamle strømavtalen din, kan du kanskje
								finne målepunkt-ID der.
							</li>
							<li>
								Du kan ta <b>kontakt med nettselskapet ditt</b>{' '}
								for å få oppgitt målepunkt-ID, eller du kan
								finne dette ved å logge inn på Min side hos
								Elhub.
							</li>
						</ul>
					</>
				) : (
					<>
						<ModalHeader>Skal du flytte til ny bolig?</ModalHeader>
						Ta kontakt med megler eller huseier, og be dem oppgi
						målernummer eller målepunkt-ID.
						<ModalHeader style={{ marginTop: '2rem' }}>
							Skal du bestille strøm til en bolig du bor i nå?
						</ModalHeader>
						<ul>
							<li>
								<b>I sikringsskapet ditt</b> finner du
								målernummeret på strømmåleren. Målernummeret
								inneholder kun tall, men antallet tall kan
								variere fra måler til måler. Nummeret står ofte
								i nærheten av en strekkode/QR-kode. Merk at
								nyere boliger ofte har strømmåleren i et
								utendørs målerskap.
							</li>
							<li>
								<b>På en gammel faktura</b> finner du
								målepunkt-ID eller målernummer.
							</li>
							<li>
								<b>Har du en app eller “Min side”</b> knyttet
								til den gamle strømavtalen din, kan du kanskje
								finne målernummer eller målepunkt-ID der.
							</li>
							<li>
								Du kan ta <b>kontakt med nettselskapet ditt</b>{' '}
								for å få oppgitt målepunkt-ID, eller du kan
								finne dette ved å logge inn på Min side hos
								Elhub.
							</li>
						</ul>
					</>
				)}
			</Popup>
		</>
	);
}
