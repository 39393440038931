import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import useBackend from './useBackend';

export default function useReferral(location) {
	const [referral, setReferral] = useState(null);
	const [loading, setLoading] = useState(true);

	const { getVervingerMinimal } = useBackend();

	useEffect(() => {
		const fetchData = async code => {
			// If no code, don't proceed
			if (!code) {
				setLoading(false);
				return;
			}

			try {
				setReferral(null);
				const referralData = await getVervingerMinimal(code);

				if (referralData?.status !== 'AKTIV') {
					setReferral(null);
					throw new Error('Referral code is not active');
				}

				setReferral(referralData);
				setLoading(false);
			} catch (e) {
				setLoading(false);
				console.error('usePower - Error fetching referral data:', e);
			}
		};

		// Get the referral code from the URL
		const code = new URLSearchParams(location?.search)?.get('vervekode');

		// If no code then redirect to /strom/verve
		if (!code && !loading) {
			console.error(
				'No valid referral-code found as parameter in URL, redirecting to /strom/verve'
			);
			navigate('/strom/verve');
			return;
		}

		fetchData(code);
		//eslint-disable-next-line
	}, []);

	return {
		loading,
		referral: !loading && referral,

		/**
		 * Function to replace the title with the referrers name
		 * @param {string} title - The title to replace
		 * @returns {string} The new title
		 */
		replaceName: (title, referral) => {
			if (!title) return;

			if (!title?.includes('En person')) {
				return title;
			}

			// If name has two or more words, remove the last word
			const name = referral?.verver?.navn
				?.split(' ')
				.slice(0, -1)
				.join(' ');

			return title.replace('En person', name || referral?.verver?.navn);
		},

		/**
		 * Function to add custom string first along with the rest of the list-elements (if any) in the HTML
		 * @param {string} html - The HTML string to parse
		 * @param {string} string - The custom string to add
		 * @returns {string} The new HTML string
		 */
		termsWithCustomTerm: (html = '', string = '') => {
			if (!html) return;

			if (typeof window === 'undefined' || !string) return html;

			const parser = new DOMParser();
			const doc = parser.parseFromString(html, 'text/html');

			const containsUnorderedList =
				doc.getElementsByTagName('ul').length > 0;

			if (!containsUnorderedList) return html;

			// If the HTML contains an unordered list, add a new list item to the start of each list
			const unorderedLists = doc.getElementsByTagName('ul');

			for (let i = 0; i < unorderedLists?.length; i++) {
				const newListElement = doc.createElement('li');

				newListElement.innerHTML = string;

				unorderedLists[i].insertBefore(
					newListElement,
					unorderedLists[i].firstChild
				);
			}

			return doc?.body?.innerHTML || html;
		},
	};
}
