import {
	ContentWrapper,
	Layout,
} from 'components/strombestilling/components/StromStyles';
import LoadingDots from 'images/icons/loading-dots-animation.inline.svg';
import React from 'react';
import styled, { css } from 'styled-components';

const CenteredContentWrapper = styled(ContentWrapper)`
	align-self: center;
	align-items: center;
	text-align: center;
	padding: 150px 0;
	gap: 0.5rem;
	${props =>
		props.theme.media.smallOnly(css`
			padding: 100px 0;
		`)}
	span {
		display: block;
	}
`;

export const BlueLoadingDots = styled(LoadingDots)`
	fill: ${p => p.theme.colors.blue600};
`;

export default function LoadingPage({ text }) {
	return (
		<Layout>
			<CenteredContentWrapper>
				<BlueLoadingDots aria-label="Laster" />
				<span>{text || 'Laster'}</span>
			</CenteredContentWrapper>
		</Layout>
	);
}
