import React, { useContext, useEffect } from 'react';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import usePageNavigation, {
	stromPages,
	stromUrlParams,
} from 'components/strombestilling/helpers/usePageNavigation';
import useTracking from 'components/strombestilling/helpers/useTracking';
import useValidate from 'components/strombestilling/helpers/useValidate';
import LoadingPage from 'components/strombestilling/pages/helperPages/loadingPage';
import { useAuth } from 'context/AuthProvider';

export default function ProtectedRoute({
	component: Component,
	page,
	...rest
}) {
	const { isAuthenticated, isLoading: isAuthLoading } = useAuth();
	const { navigatePage, getLogoutUrl } = usePageNavigation();
	const { trackStepViewed } = useTracking();
	const { isValidating } = useValidate();

	const {
		isLoading: isContextLoading,
		order,
		clearDataAndSignOut,
		checkAndInitiateData,
		storedElektroOrder,
	} = useContext(StromContext);

	// Make sure all data is updated
	useEffect(() => {
		const shouldLogout = page?.isFirstPage || page?.isLastpage;

		if (shouldLogout && !storedElektroOrder) {
			clearDataAndSignOut(getLogoutUrl(page?.url), page?.isFirstPage);
		} else {
			checkAndInitiateData();
		}

		// eslint-disable-next-line
	}, []);

	// Send user to first page if not logged in
	useEffect(() => {
		if (
			!isAuthenticated &&
			!isAuthLoading &&
			!page?.isFirstPage &&
			!page?.isLastpage
		) {
			navigatePage(stromPages.login.url);
		}
		// eslint-disable-next-line
	}, [isAuthenticated, isAuthLoading]);

	// Track step viewed
	useEffect(() => {
		if (page?.step && !isContextLoading && order?.tariffData) {
			const params = new URLSearchParams(rest?.location?.search);
			let paramValue = params?.entries()?.next()?.value?.[0];

			if (page === stromPages.orderFailed && !paramValue) {
				paramValue = stromUrlParams.unknownError;
			}

			trackStepViewed(page.step, paramValue);
		}
		// eslint-disable-next-line
	}, [isContextLoading, order.tariffData]);

	if (
		isAuthLoading ||
		isValidating ||
		(!isAuthenticated && !page?.isFirstPage && !page?.isLastpage)
	) {
		return <LoadingPage />;
	}

	return <Component {...rest} />;
}
