import { ELEKTRO_PRODUCT_ORDER_KEY } from 'components/product-order/Provider';
import useBackend from 'components/strombestilling/helpers/useBackend';
import usePageNavigation, {
	stromPages,
	stromUrlParams,
} from 'components/strombestilling/helpers/usePageNavigation';
import { validationErrorNames } from 'components/strombestilling/helpers/useValidate';
import { useAuth } from 'context/AuthProvider';
import { setupContext } from 'libs/analytics';
import React, { createContext, useEffect, useRef, useState } from 'react';

export const emptyAddress = {
	gate: null,
	husnummer: null,
	postnummer: null,
	poststed: null,
};

export const storageNames = {
	order: 'nte-strom-order',
	user: 'nte-strom-user',
	searchResult: 'nte-strom-search-result',
	logoutOrderInfo: 'nte-strom-order-info',
};

export const StromContext = createContext({
	isLoading: false,
	order: null,
	updateOrder: () => {},
	user: null,
	updateUser: () => {},
	searchResult: null,
	updateSearchResult: () => {},
	fetchAndSetUserData: () => {},
	storeBeforeLogout: () => {},
	clearDataAndSignOut: () => {},
	checkAndInitiateData: () => {},
	constructOrder: () => {},
	trackingEvents: [],
	isTrackingPending: false,
});

export default function StrombestillingProvider({
	kilde,
	produkt = JSON.parse(localStorage?.getItem(storageNames.logoutOrderInfo))
		?.tariffId || 'standard-spotpris-privat',
	vervekode,
	salgskode,
	storedElektroOrder,
	children,
}) {
	const {
		isAuthenticated,
		isLoading: isAuthLoading,
		user: authUser,
		logout,
	} = useAuth();
	const {
		getTariff,
		getSale,
		getVervingerMinimal,
		getUserInfo,
		validateTariffregler,
	} = useBackend();
	const { navigatePage, getLogoutUrl } = usePageNavigation();

	const trackingEvents = useRef([]);
	const isTrackingPending = useRef(false);

	const [isLoading, setIsLoading] = useState(false);

	const defaultOrder = {
		kilde: kilde,
		tariffId: produkt,
		tariffData: null,
		fakturamerke: null,
		vervekode: vervekode || null,
		verveData: null,
		salgskode: salgskode || null,
		salgsData: null,
		samtykkeTidligStart: false,
		samtykkeMarkedsfoering: false,
		maalere: [], // Format: { maalernummer: '', maalepunktId: '' }
		maalerAdresse: emptyAddress,
		fakturaAdresse: emptyAddress,
		oppstartsdato: null,
		isManual: false,
		manualMsg: null,
		isBedrift: false,
		bedriftData: null,
		isElektroOrder: !!storedElektroOrder || false,
	};
	const [order, setOrder] = useState(defaultOrder);
	function updateOrder(newOrder) {
		setOrder({ ...order, ...newOrder });
	}
	const defaultUser = {
		fornavn: null,
		mellomnavn: null,
		etternavn: null,
		ssn: null,
		mobil: null,
		landskode: null, // Tlf. landskode, f.eks. 47
		epost: null,
		folkeregAdresse: emptyAddress,
		maalere: [], // Målere på folkeregistrert adresse, format: { maalernummer: '', maalepunktId: '' }
		orgnr: null,
	};

	const [user, setUser] = useState(defaultUser);
	function updateUser(newUser) {
		setUser({ ...user, ...newUser });
	}

	const defaultSearchResult = {
		maalere: [], // Format: { maalernummer: '', maalepunktId: '' }
		maalerAdresse: emptyAddress,
	};
	const [searchResult, setSearchResult] = useState(defaultSearchResult);
	function updateSearchResult(newSearchResult) {
		setSearchResult({ ...searchResult, ...newSearchResult });
	}

	useEffect(() => {
		if (order !== defaultOrder) {
			sessionStorage.setItem(storageNames.order, JSON.stringify(order));
		}
		// eslint-disable-next-line
	}, [order]);

	useEffect(() => {
		if (user !== defaultUser) {
			sessionStorage.setItem(storageNames.user, JSON.stringify(user));
		}
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		if (searchResult !== defaultSearchResult) {
			sessionStorage.setItem(
				storageNames.searchResult,
				JSON.stringify(searchResult)
			);
		}
		// eslint-disable-next-line
	}, [searchResult]);

	async function fetchAndSetOrderData() {
		setIsLoading(true);

		// Validate tariff and vervekode
		const validation = await validateTariffregler(
			order.tariffId,
			null,
			order.vervekode,
			order.salgskode
		);

		if (validation?.errorList?.length) {
			switch (validation.errorList[0].validationName) {
				case validationErrorNames.GyldigTariffId:
					navigatePage(
						stromPages.orderFailed.url,
						stromUrlParams.tariffError
					);
					break;
				case validationErrorNames.GyldigVervekode:
					navigatePage(
						stromPages.orderFailed.url,
						stromUrlParams.vervekodeError
					);
					break;
				case validationErrorNames.TariffKreverVervekode:
					navigatePage(
						stromPages.orderFailed.url,
						stromUrlParams.vervekodeError
					);
					break;
				case validationErrorNames.SalgskodeEksisterer:
					navigatePage(
						stromPages.orderFailed.url,
						stromUrlParams.salgskodeError
					);
					break;
				case validationErrorNames.SalgskodeUbrukt:
					navigatePage(stromPages.salesCodeUsed.url);
					break;
				case validationErrorNames.TariffKreverSalgskode:
					navigatePage(
						stromPages.orderFailed.url,
						stromUrlParams.salgskodeError
					);
					break;
				default:
					break;
			}
		}

		// Set tariff, vervekode and add info from stored elektro order (downpayment)
		let newOrder = {};
		if (order.tariffId && !order?.tariffData) {
			const tariffData = await getTariff(order.tariffId);

			if (tariffData && !tariffData.error) {
				newOrder.tariffData = tariffData;
				newOrder.isBedrift = tariffData.kundetype === 'BEDRIFT';
			}
		}
		if (order.salgskode && !order?.salgsData) {
			const salgsData = await getSale(order.salgskode);
			if (salgsData && !salgsData.error) {
				newOrder.salgsData = salgsData;
			}
		}
		if (order.vervekode && !order.verveData) {
			const verveData = await getVervingerMinimal(order.vervekode);
			if (verveData && !verveData.error) {
				newOrder.verveData = verveData;
			}
		}

		// Set fakturaadresse from storedElektroOrder
		if (!!storedElektroOrder?.invoiceAddress) {
			newOrder.fakturaAdresse = {
				gate: storedElektroOrder?.invoiceAddress?.street,
				husnummer: storedElektroOrder?.invoiceAddress?.number,
				postnummer: storedElektroOrder?.invoiceAddress?.zip,
				poststed: storedElektroOrder?.invoiceAddress?.city,
			};
		}

		updateOrder({
			...newOrder,
		});
		setIsLoading(false);
	}

	function splitPhoneAndCc(number) {
		if (!number || !number.length) return null;
		const cleanedNumber = number.replaceAll(' ', '');
		if (cleanedNumber.length > 8) {
			return {
				cc: cleanedNumber.slice(0, -8),
				phone: cleanedNumber.slice(-8),
			};
		} else {
			return { cc: null, phone: cleanedNumber || null };
		}
	}

	async function fetchAndSetUserData() {
		setIsLoading(true);

		if (!authUser?.nationalId) {
			storeBeforeLogout();
			const logoutUrl = getLogoutUrl(
				stromPages.orderFailed.url,
				stromUrlParams.loginSsnError
			);
			logout({ redirect: logoutUrl });
		}

		const folkeregUser = await getUserInfo(authUser.nationalId);

		if (folkeregUser && !folkeregUser.error) {
			const newUser = {
				fornavn: folkeregUser.persondata.fornavn,
				mellomnavn: folkeregUser.persondata.mellomnavn,
				etternavn: folkeregUser.persondata.etternavn,
				ssn: authUser.nationalId,
				epost: authUser.email,
				mobil: splitPhoneAndCc(authUser.phone_number)?.phone,
				landskode: splitPhoneAndCc(authUser.phone_number)?.cc,
				folkeregAdresse: folkeregUser.persondata?.adresse,
				maalere: folkeregUser.maalepunkter?.length
					? folkeregUser.maalepunkter.map(m => {
							return {
								maalernummer: m.maalernummer,
								maalepunktId: m.maalepunktId,
							};
					  })
					: [],
			};

			if (!!storedElektroOrder) {
				if (storedElektroOrder?.firstname) {
					newUser.fornavn = storedElektroOrder?.firstname;
					newUser.mellomnavn = ''; // User can't edit own name during strombestilling, avoid risk of double middlenames
				}
				if (storedElektroOrder?.lastname) {
					newUser.etternavn = storedElektroOrder?.lastname;
				}
				if (storedElektroOrder?.phone) {
					newUser.mobil = splitPhoneAndCc(
						storedElektroOrder?.phone
					)?.phone;
				}
				if (storedElektroOrder?.email) {
					newUser.epost = storedElektroOrder?.email;
				}
			}

			updateUser({ ...newUser });
			setIsLoading(false);
		} else {
			setIsLoading(false);
			storeBeforeLogout();
			const logoutUrl = getLogoutUrl(
				stromPages.orderFailed.url,
				stromUrlParams.loginFetchError
			);
			logout({
				redirect: logoutUrl,
			});
		}
	}

	function storeBeforeLogout(orderId) {
		localStorage.setItem(
			storageNames.logoutOrderInfo,
			JSON.stringify({
				orderId: orderId,
				tariffId: order.tariffId,
				salesCode: order.salgskode,
				numOfSales: order.maalere.length,
				startDate: order.oppstartsdato,
				isElektroOrder: order?.isElektroOrder || false,
				meterIds: order?.maalere?.map(m => m?.maalepunktId)?.join(','),
			})
		);
	}

	// Clear elektro order from storage
	function clearElektroOrder() {
		if (typeof window === 'undefined') return;
		sessionStorage?.removeItem(ELEKTRO_PRODUCT_ORDER_KEY);
	}

	async function clearDataAndSignOut(url, doRefetch) {
		setIsLoading(true);

		sessionStorage.removeItem(storageNames.order);
		sessionStorage.removeItem(storageNames.user);
		sessionStorage.removeItem(storageNames.searchResult);

		setOrder(defaultOrder);
		setUser(defaultUser);
		setSearchResult(defaultSearchResult);

		if (doRefetch) {
			fetchAndSetOrderData().then(() => {
				if (isAuthenticated && !isAuthLoading) {
					storeBeforeLogout();
					logout({ redirect: url });
				}
				setIsLoading(false);
			});
		} else {
			if (isAuthenticated && !isAuthLoading) {
				storeBeforeLogout();
				logout({ redirect: url });
			}
			setIsLoading(false);
		}
	}

	async function checkAndInitiateData() {
		setIsLoading(true);

		if (!order || order === defaultOrder) {
			const orderStored = JSON.parse(
				sessionStorage.getItem(storageNames.order)
			);
			if (orderStored && order !== orderStored) {
				setOrder({ ...orderStored });
			} else if (
				(order.tariffId && !order?.tariffData) ||
				(order.vervekode && !order?.verveData)
			) {
				await fetchAndSetOrderData();
			}
		}
		if (!user || user === defaultUser) {
			const userStored = JSON.parse(
				sessionStorage.getItem(storageNames.user)
			);
			if (userStored && userStored !== user) {
				setUser({ ...userStored });
			}
		}
		if (!searchResult || searchResult === defaultSearchResult) {
			const searchResultStored = JSON.parse(
				sessionStorage.getItem(storageNames.searchResult)
			);
			if (searchResultStored && searchResult !== searchResultStored) {
				setSearchResult({ ...searchResultStored });
			}
		}

		setIsLoading(false);
	}

	function constructOrder() {
		const trackingContext = setupContext();
		return {
			kilde: order.kilde,
			tariffId: order.tariffId,
			fakturamerke: order.fakturamerke,

			vervekode: order.vervekode,
			salgskode: order.salgskode,
			tillatMarkedfoering: order.samtykkeMarkedsfoering,

			maalepunktIder: order.maalere.map(m => m.maalepunktId),
			oppstartsdato: order.oppstartsdato,
			kunde: {
				ssn: user.ssn,
				organisasjonsnummer: user.orgnr,
				fornavn:
					user.fornavn +
					(user.mellomnavn ? ' ' + user.mellomnavn : ''),
				etternavn: user.etternavn,
				kundenr: null,
				mobil: user.landskode
					? '+' + user.landskode + user.mobil
					: user.mobil,
				epost: user.epost,
				kundetype: order.isBedrift ? 'BEDRIFT' : 'PERSON',
				adresse: order.fakturaAdresse,
			},
			manuellBehandling: order.isManual
				? {
						adresse: order.maalerAdresse,
						melding: order.manualMsg,
				  }
				: null,

			parametre: {
				// campaign:
				source: trackingContext?.campaign?.source,
				medium: trackingContext?.campaign?.medium,
				name: trackingContext?.campaign?.name,
				content: trackingContext?.campaign?.content,
				// categoryPreferences:
				tekniske: trackingContext?.categoryPreferences?.tekniske,
				statistikk: trackingContext?.categoryPreferences?.statistikk,
				funksjonelle:
					trackingContext?.categoryPreferences?.funksjonelle,
				markedsføring:
					trackingContext?.categoryPreferences?.markedsføring,
				// pixel:
				tiktokPixel: trackingContext?.pixel?.tiktok,
				snapchatPixel: trackingContext?.pixel?.snapchat,
				metaPixel: trackingContext?.pixel?.facebook,
				googlePixel: trackingContext?.pixel?.google,
				// clickId:
				tiktokClickId: trackingContext?.clickId?.tiktok,
				snapchatClickId: trackingContext?.clickId?.snapchat,
				metaClickId: trackingContext?.clickId?.facebook,
				googleClickId: trackingContext?.clickId?.google,
				// other:
				ip: trackingContext?.ip, // Current ip user address
				os: trackingContext?.os?.name, // Windows, Mac, etc.
				referrer: trackingContext?.page?.referrer, // Where the user came from, google, facebook, etc.
				url: trackingContext?.url, // Current browser url
				userAgent: navigator?.userAgent, // Mozilla, Chrome, AppleWebKit etc.
			},
		};
	}

	return (
		<StromContext.Provider
			value={{
				isLoading,
				order,
				updateOrder,
				user,
				updateUser,
				searchResult,
				updateSearchResult,
				fetchAndSetUserData,
				storeBeforeLogout,
				clearDataAndSignOut,
				checkAndInitiateData,
				constructOrder,
				trackingEvents,
				isTrackingPending,
				storedElektroOrder,
				clearElektroOrder,
			}}>
			{children}
		</StromContext.Provider>
	);
}
