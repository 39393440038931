import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/index';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const Required = styled.span`
	color: ${p => p.theme.colors.coral800};
	display: contents;
`;

const MsgWrapper = styled.div`
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 24px;
	display: flex;
	align-items: center;
`;

const ErrorWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 0.75rem;
	background: ${p =>
		p.isDark ? `${p.theme.colors.coral900}` : `${p.theme.colors.coral100}`};
	padding: 0.75rem;
	border-radius: 0.5rem;
	margin-top: 0.5rem;
	font-size: 0.875rem;
	color: ${p =>
		p.isDark ? `${p.theme.colors.white}` : `${p.theme.colors.grey900}`};
	line-height: 1.375rem;
	svg:first-child {
		padding-top: 0.2rem;
		color: ${p =>
			p.isDark
				? `${p.theme.colors.white}`
				: `${p.theme.colors.coral900}`};
		font-size: 1rem;
	}
`;

const InfoWrapper = styled(ErrorWrapper)`
	background: ${p => p.theme.colors.blue100};
	svg:first-child {
		color: ${p => p.theme.colors.blue600};
	}
`;

const CelebrationWrapper = styled(ErrorWrapper)`
	background: ${p => p.theme.colors.green100};
	svg:first-child {
		color: ${p => p.theme.colors.green600};
	}
`;

export function RequiredAsterisk() {
	return <Required>*</Required>;
}

export function StromFormRequired() {
	return (
		<MsgWrapper>
			<RequiredAsterisk /> Felter med denne markeringen må krysses av før
			man kan gå videre
		</MsgWrapper>
	);
}

export function StromFormError({ error, isDark }) {
	return (
		<ErrorWrapper tabindex="0" role="alert" isDark={isDark}>
			<FontAwesomeIcon icon={faExclamationCircle} />
			{error}
		</ErrorWrapper>
	);
}

export function StromFormInfo({ info }) {
	return (
		<InfoWrapper tabindex="0" role="alert">
			<FontAwesomeIcon icon={faExclamationCircle} />
			{info}
		</InfoWrapper>
	);
}

export function StromFormCelebration({ info }) {
	return (
		<CelebrationWrapper tabindex="0" role="alert">
			<FontAwesomeIcon icon={faCheckCircle} />
			{info}
		</CelebrationWrapper>
	);
}
