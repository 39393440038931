import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClearButton } from 'components/strombestilling/components/StromStyles';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	border: 1px solid ${p => p.theme.colors.grey300};
	border-radius: 9px;
	padding: 20px 18px;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 18px;
`;

const Icon = styled.div`
	color: ${p => p.theme.colors.blue600};
	font-size: 1.125rem;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const BoxTitle = styled.div`
	font-weight: 500;
	line-height: 1.1875rem;
	max-width: ${p => (p.limitWidth ? 'calc(100% - 75px)' : '100%')};
`;

const BoxSubtitle = styled.div`
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 130%;
`;

const InfoRow = styled(BoxSubtitle)`
	color: ${p => p.theme.colors.grey800};
`;

const EditButton = styled(ClearButton)`
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
`;

function InfoBox({ icon, title, subtitle, values, onEdit, ariaLabel }) {
	return (
		<Wrapper>
			{icon && <Icon>{icon}</Icon>}
			{onEdit && (
				<EditButton
					onClick={onEdit}
					aria-label={ariaLabel || 'Rediger'}>
					Rediger
					<FontAwesomeIcon icon={faPencil} />
				</EditButton>
			)}
			<InfoWrapper>
				{title && <BoxTitle limitWidth={!!onEdit}>{title}</BoxTitle>}
				{subtitle && <BoxSubtitle>{subtitle}</BoxSubtitle>}
				{values?.map((val, i) => {
					return (
						<InfoRow key={i}>
							<b>{val.key}:</b> {val.value}
						</InfoRow>
					);
				})}
			</InfoWrapper>
		</Wrapper>
	);
}

export default InfoBox;
