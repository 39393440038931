import { AnchorLink } from 'components/Link';
import { ProductBoxMobileOnly } from 'components/strombestilling/components/ProductBox';
import { StromFormInfo } from 'components/strombestilling/components/StromFormMsgs';
import {
	ContentWrapper,
	Header,
	Layout,
	LinkButton,
	Push,
	StyledListOfRuleErrors,
} from 'components/strombestilling/components/StromStyles';
import { StromContext } from 'components/strombestilling/helpers/strombestillingProvider';
import usePageNavigation, {
	stromUrlParams,
} from 'components/strombestilling/helpers/usePageNavigation';
import useSale, {
	salgsStatuser,
} from 'components/strombestilling/helpers/useSale';
import useValidate, {
	contactSpan,
} from 'components/strombestilling/helpers/useValidate';
import LoadingPage from 'components/strombestilling/pages/helperPages/loadingPage';
import Stikkontakt from 'images/strombestilling/stikkontakt.inline.svg';
import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

export const StikkkontaktIcon = styled(Stikkontakt)`
	align-self: center;
	max-height: 17rem;
	${p =>
		p.theme.media.smallOnly(css`
			max-height: 14rem;
		`)};
`;

const StyledLinkButton = styled(LinkButton)`
	display: inline-block;
	font-size: 1rem;
`;

// Mirrors orderFailed.js
export default function ErrorPage({ location }) {
	const { order, user } = useContext(StromContext);
	const { getValidatedMeters, isValidating } = useValidate();
	const { updateSaleStatus } = useSale();
	const { goBack } = usePageNavigation();

	const [isLoading, setIsLoading] = useState(false);
	const [header, setHeader] = useState('');
	const [body, setBody] = useState(null);

	const params = new URLSearchParams(location.search);

	useEffect(() => {
		updateSaleStatus(salgsStatuser.meterAlreadyCustomer);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!header || !body) {
			switch (params?.entries()?.next()?.value?.[0]) {
				case stromUrlParams.ruleBroken:
					setIsLoading(true);
					async function runValidate() {
						const validationResult = await getValidatedMeters([
							...user?.maalere,
						]);
						setHeader(
							`Denne måleren kan ikke få ${
								order?.tariffData?.navn || 'denne strømavtalen'
							}`
						);
						setBody(
							<span>
								{user?.maalere?.length === 1
									? 'Måleren'
									: 'Målerene'}{' '}
								vi fant på {user.folkeregAdresse?.gate}{' '}
								{user.folkeregAdresse?.husnummer} oppfyller ikke
								vilkår for{' '}
								{order?.tariffData?.navn ||
									'dev valgte strømavtalen'}
								.{' '}
								<StyledLinkButton onClick={goBack}>
									{' '}
									Gå tilbake
								</StyledLinkButton>{' '}
								og velg en annen adresse eller se{' '}
								<AnchorLink href="/strom" target="_blank">
									alle våre strømavtaler
								</AnchorLink>
								.
								<br />
								<br />
								Hvis du har spørsmål, vennligst ta kontakt med{' '}
								{contactSpan}. <br />
								<br />
								{validationResult?.infoArray?.length > 0 && (
									<StromFormInfo
										info={
											<StyledListOfRuleErrors
												single={
													validationResult?.infoArray
														?.length < 2
												}>
												{validationResult?.infoArray.map(
													(info, i) => (
														<li key={i}>{info}</li>
													)
												)}
											</StyledListOfRuleErrors>
										}
									/>
								)}
							</span>
						);
						setIsLoading(false);
					}
					runValidate();
					break;
				default:
					setHeader('Noe gikk galt');
					setBody(
						<span>
							Vi beklager, en uventet feil oppstod, og vi kan ikke
							fortsette bestillingen.
							<br />
							<br />
							Prøv gjerne igjen senere, eller ta kontakt med{' '}
							{contactSpan}, så hjelper vi deg gjerne.
						</span>
					);
					break;
			}
		}
		// eslint-disable-next-line
	}, [params]);

	if (isLoading || isValidating || !header) {
		return <LoadingPage />;
	}
	return (
		<Layout>
			<Header>{header}</Header>
			<ContentWrapper>
				<StikkkontaktIcon
					role="img"
					aria-label="Illustrasjon av en stikkontakt som blir plugget ut"
				/>
				{body}
				<Push />
				<ProductBoxMobileOnly header="Vilkår" />
			</ContentWrapper>
		</Layout>
	);
}
