import styled, { css } from 'styled-components';

export const Layout = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	grid-area: 'content';
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-self: flex-start;
	max-width: 100%;
	${p =>
		p.theme.media.smallOnly(css`
			align-self: center;
		`)};
`;

// For double gap in a flex container
export const Push = styled.div``;

export const Header = styled.h1`
	font-weight: 500;
	font-size: 1.625rem;
	line-height: 1.875rem;
	margin: 0;
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 1.875rem;
			line-height: 2.1875rem;
			text-align: center;
			margin: 0;
		`)};
`;

export const SubHeader = styled.h2`
	font-weight: 500;
	font-size: 1.375rem;
	line-height: 1.75rem;
	margin: 0;
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 1.25rem;
			line-height: 1.8125rem;
		`)};
`;

export const H3 = styled.h3`
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5rem;
	margin: 0;
`;

export const Paragraph = styled.p`
	font-weight: 400;
	font-size: 1.0625rem;
	line-height: 1.8125rem;
	margin: 0;
	${p =>
		p.theme.media.smallOnly(css`
			text-align: center;
		`)};
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	button[type='submit'] {
		margin-top: 1rem;
		display: flex;
	}

	/* Changing color of the autofill */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		-webkit-box-shadow: 0 0 0px 1000px ${p => p.theme.colors.blue100} inset;
		font-size: 17px !important;
	}
`;

export const Label = styled.label`
	font-size: 0.875rem;
	font-weight: 500;
	color: ${props => props.theme.colors.grey800};
`;

export const Button = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 14px;
	gap: 8px;

	height: 36px;
	max-width: 480px;
	min-width: 200px;
	background: ${p => p.theme.colors.blue600};
	border-radius: 6px;

	color: ${p => p.theme.colors.white};
	font-weight: 500;
	font-size: 0.75rem !important;
	line-height: 0.8125rem;
	letter-spacing: 0.025em;

	${p =>
		p.theme.media.medium(css`
			width: max-content;
			padding: 1.5rem 2rem;
			font-size: 1rem !important;
			border-radius: ${p => p.theme.utils.borderRadius};
		`)};
	&:hover {
		opacity: 0.9;
	}
	&:disabled {
		cursor: auto;
		opacity: 1;
		background: ${p => p.theme.colors.grey700};
	}
`;

export const ButtonSecondary = styled(Button)`
	background: ${p => p.theme.colors.white};
	color: ${p => p.theme.colors.blue600};
	border: 2px solid ${p => p.theme.colors.blue600};
	&:hover {
		border: 2px solid transparent;
		background: ${p => p.theme.colors.blue600};
		color: white;
	}
`;

export const ClearButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;

	margin: 0;
	padding: 8px 14px;
	border: none;
	border-radius: ${p => p.theme.utils.borderRadius};
	background-color: transparent;

	color: ${p => p.theme.colors.blue600};
	font-size: 0.875rem;

	&:hover {
		opacity: 1;
		background: ${p => p.theme.colors.blue200};
	}
	&:focus-visible {
		border: none;
		outline: none;
		background: ${p => p.theme.colors.blue200};
	}
`;

export const GreenButton = styled(Button)`
	background: ${p => p.theme.colors.green700};
`;

export const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

// Button that looks like a link
export const LinkButton = styled.button`
	background: none;
	border: none;
	padding: 0;
	width: fit-content;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 0.875rem;
	line-height: 1.8125rem;
	text-align: center;
	text-decoration-line: underline;
	color: ${p => p.theme.colors.blue600};
`;

export const StyledListOfRuleErrors = styled.ul`
	margin: 0;
	padding-left: 1rem;
	list-style-type: ${props => (props.single ? 'none' : 'disc')};
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding-left: ${props => (props.single ? '0' : '0.5rem')};
`;

export const RuleErrorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`;
