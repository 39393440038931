/* import {
	StromContext,
	storageNames,
} from 'components/strombestilling/helpers/strombestillingProvider';
import useBackend from 'components/strombestilling/helpers/useBackend';
import { useContext } from 'react'; */

export const DISCOUNT_PER_SALE = 500;

export const salgsStatuser = {
	new: 'NY',
	opened: 'AAPNET',
	loggedIn: 'LOGGET_INN',
	meterFound: 'MAALER_FUNNET',
	meterNotFound: 'MAALER_IKKE_FUNNET',
	meterFoundAfterSearch: 'MAALER_FUNNET_ETTER_SOK',
	meterAlreadyCustomer: 'MAALER_HAR_NTE',
	lastStep: 'SISTE_STEG',
	failedTech: 'TEKNISK_FEIL',
	failed: 'FEILET',
	done: 'FULLFOERT',
};

export default function useSale() {
	//const { order } = useContext(StromContext);
	//const { updateSaleStatus: updateStatus } = useBackend();

	/* const orderInfo = JSON.parse(
		localStorage.getItem(storageNames.logoutOrderInfo)
	); */

	//const salgskode = order.salgskode || orderInfo?.salesCode;

	function updateSaleStatus(status) {
		return;
		// We don't have a deal with a seller right now
		/*
		if (salgskode) {
			updateStatus(salgskode, status);
		}
		*/
	}

	return {
		updateSaleStatus,
	};
}
